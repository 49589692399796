import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { LazyImage } from '@/components/LazyImage'

import Etiquetas from './components/Etiquetas'
import Precos from './components/Precos'

interface PropsCardItemCatalogo {
  produto: ProdutoCatalogo
}

export type TipoPublicacaoCatalogo =
  | 'LISTA_DESEJO'
  | 'MELHOR_FABRICANTE'
  | 'LANCAMENTO'
  | 'PROMOCAO'
  | 'MENOR_PRECO'
  | 'PROMOCAO_TEMPORARIA'
  | 'PRONTA_ENTREGA'
  | 'SITUACAO_ITEM_LISTA_DESEJO'
  | null

export interface ProdutoCatalogo {
  categoria: CategoriaProduto
  foto: string
  grades: GradeProduto[]
  id_colaborador_ponto?: number
  id_produto: number
  nome: string
  preco: number
  preco_original: number
  valor_parcela: number
  parcelas: number
  quantidade_vendida: number
}

export interface GradeProduto {
  estoque: number
  nome_tamanho: string
}

export interface CategoriaProduto {
  tipo?: TipoPublicacaoCatalogo
  valor?: string
}

export function CardItemCatalogo(props: PropsCardItemCatalogo): JSX.Element {
  const [urlProduto, setUrlProduto] = useState(`/produto/${props?.produto?.id_produto}`)
  const router = useRouter()

  useEffect(() => {
    const infos = props.produto
    if (infos.categoria.tipo === 'PRONTA_ENTREGA') {
      const parametros = new URLSearchParams({
        tipo: 'PRONTA_ENTREGA',
        id_colaborador_ponto: (infos.id_colaborador_ponto ?? '').toString()
      })
      setUrlProduto(`/produto/${infos?.id_produto}?${parametros}`)
    } else {
      setUrlProduto(`/produto/${infos?.id_produto}`)
    }
  }, [props.produto])

  return (
    <ContainerItemCatalogo>
      <a
        href={urlProduto}
        onClick={e => {
          e.preventDefault()
          router.push(urlProduto)
        }}
      >
        <figure>
          <LazyImage src={props.produto.foto} alt={`Foto do produto ${props.produto.id_produto}`} />
          <Etiquetas categoria={props.produto.categoria} quantidadeVendas={props.produto.quantidade_vendida} />
        </figure>
        <section>
          <div>
            <NomeProduto nome={props.produto.nome}>#</NomeProduto>
            <div className="grades">
              {props.produto.grades?.map((grade, indexGrade) => (
                <span key={`${indexGrade}_${grade.nome_tamanho}`}>{grade.nome_tamanho}</span>
              ))}
            </div>
            <Precos
              parcelas={props.produto.valor_parcela}
              preco={props.produto.preco}
              precoOriginal={props.produto.preco_original}
              valorParcela={props.produto.valor_parcela}
            />
          </div>
        </section>
      </a>
    </ContainerItemCatalogo>
  )
}

const ContainerItemCatalogo = styled.div`
  width: 100%;
  border-radius: 5px;
  background-color: var(--branco);
  box-shadow: 0 0.25rem 0.25rem var(--cor-sombra);
  overflow: hidden;

  figure {
    position: relative;
    margin-bottom: 0.2rem;
    width: 100%;

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.313rem 0.313rem 0 0;
      cursor: pointer;
    }
  }

  section {
    padding: 0.25rem;
    text-align: center;

    .grades {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 0 0.1rem;
      margin-top: 0.1rem;
      gap: 0.15rem;

      span {
        background-color: var(--cor-primaria);
        color: var(--branco);
        font-weight: 400;
        border-radius: 0.2rem;
        font-size: 0.8rem;
        padding: 0 0.2rem;
      }
    }
  }
`

const NomeProduto = styled.p<{ nome: string }>`
  visibility: hidden;
  position: relative;

  &::after {
    visibility: visible;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    content: '${props => props.nome}';
  }
`
