/** @issue https://github.com/mobilestock/frontend/issues/20 */
import { BaseSyntheticEvent } from 'react'

export default {
  formataTelefone(value: string): string {
    const valueRaw = value.replace(/[^0-9]/g, '')
    let numberFormat = value.replace(/[^0-9]/g, '')
    if (valueRaw.length > 2) {
      numberFormat = '(' + numberFormat.slice(0, 2) + ')' + ' ' + numberFormat.slice(2)
    }
    if (valueRaw.length > 6 && valueRaw.length <= 10) {
      numberFormat = numberFormat.slice(0, 9) + ' ' + numberFormat.slice(9)
    }
    if (valueRaw.length > 10) {
      numberFormat = numberFormat.slice(0, 10) + '-' + numberFormat.slice(10)
    }

    return numberFormat?.slice(0, 15)
  },
  formataValorEmReais(valor: number): string {
    const valorFormatado = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(valor)

    return valorFormatado
  },
  imagemOnError(evento: BaseSyntheticEvent<Event, EventTarget & HTMLImageElement>): void {
    evento.target.src = '/resources/images/broken-image.png'
  }
}
