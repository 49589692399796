import { TipoPublicacaoCatalogo } from '..'

import { FaBoxOpen, FaCheckCircle } from 'react-icons/fa'
import { FiMinus } from 'react-icons/fi'
import { GiPriceTag } from 'react-icons/gi'
import { MdNewReleases } from 'react-icons/md'
import { RiTimer2Fill } from 'react-icons/ri'

interface PropsIconeEtiqueta {
  tipo?: TipoPublicacaoCatalogo
  tamanho?: string
}

export function IconeEtiqueta(props: PropsIconeEtiqueta): JSX.Element {
  switch (props.tipo) {
    case 'MELHOR_FABRICANTE':
      return <FaCheckCircle size={props.tamanho ?? '1.4rem'} />
    case 'LANCAMENTO':
      return <MdNewReleases size={props.tamanho ?? '1rem'} />
    case 'MENOR_PRECO':
      return <GiPriceTag size={props.tamanho ?? '1rem'} />
    case 'PROMOCAO':
      return <FiMinus size={props.tamanho ?? '1rem'} />
    case 'PROMOCAO_TEMPORARIA':
      return <RiTimer2Fill size={props.tamanho ?? '1.5rem'} />
    case 'PRONTA_ENTREGA':
      return <FaBoxOpen size={props.tamanho ?? '1rem'} />
    default:
      return <div></div>
  }
}
