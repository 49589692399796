import { CategoriaProduto } from '..'

import styled from 'styled-components'

import { IconeEtiqueta } from './IconeEtiqueta'

interface EtiquetaProps {
  categoria: CategoriaProduto
  quantidadeVendas: number
}

export default function Etiquetas({ categoria, quantidadeVendas }: EtiquetaProps): JSX.Element {
  let classeCor = ''
  switch (categoria?.tipo) {
    case 'MELHOR_FABRICANTE':
      classeCor = 'fundo-destaque'
      break
    case 'LANCAMENTO':
      classeCor = 'fundo-verde'
      break
    case 'MENOR_PRECO':
      classeCor = 'fundo-verde'
      break
    case 'PRONTA_ENTREGA':
      classeCor = 'fundo-verde'
      break
    case 'PROMOCAO':
      classeCor = 'fundo-vermelho'
      break
    case 'PROMOCAO_TEMPORARIA':
      classeCor = 'fundo-vermelho'
      break
  }

  return (
    <ContainerEtiquetas>
      {classeCor && (
        <div className={`etiqueta ${classeCor}`}>
          <IconeEtiqueta tipo={categoria.tipo} tamanho="1rem" />
          {categoria.valor && <span className="observacao">{categoria.valor}</span>}
        </div>
      )}
      {quantidadeVendas > 0 && (
        <div className="etiqueta vendas">
          {quantidadeVendas} {quantidadeVendas > 1 ? 'Vendidos' : 'Vendido'}
        </div>
      )}
    </ContainerEtiquetas>
  )
}

const ContainerEtiquetas = styled.div`
  width: 100%;
  position: absolute;
  padding: 0.3rem 0.3rem 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    min-height: 1.3rem;
    min-width: 1.3rem;

    &.etiqueta {
      margin-left: 0.3rem;
      color: var(--branco);
      border-radius: 6.25rem;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        padding-left: 0.1rem;
      }

      :has(span) {
        padding: 0 0.3rem;
      }
    }

    &.vendas {
      background-color: var(--preto);
      padding: 0 0.3rem;
      font-weight: 600;
      max-height: 1.406rem;
    }
  }

  .fundo-verde {
    background-color: var(--card-catalogo-fundo-verde);
  }

  .fundo-vermelho {
    background-color: var(--card-catalogo-fundo-vermelho);
  }

  .fundo-destaque {
    background-color: var(--card-catalogo-fundo-azul);
  }
`
