import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { BiChevronLeft } from 'react-icons/bi'
import { FiSettings, FiShare2 } from 'react-icons/fi'
import styled from 'styled-components'

import { Botao } from '@/components/botoes/Botao'
import { useConfiguracoesLoja } from '@/hooks/useConfiguracoesLoja'
import { useGlobal } from '@/hooks/useGlobal'
import { usePesquisa } from '@/hooks/usePesquisa'

type PropsEsquerda = 'VOLTAR' | 'CONFIGURACOES'
type PropsDireita = 'COMPARTILHAR' | 'COMPARTILHAR_PESQUISA'

interface CabecalhoProps {
  direita?: PropsDireita[]
  esquerda?: PropsEsquerda[]
  titulo?: string
}

export const Cabecalho: React.FC<CabecalhoProps> = props => {
  const { configsLoja } = useConfiguracoesLoja()
  const { compartilharLink } = useGlobal()
  const router = useRouter()
  const pesquisaContext = usePesquisa()

  function retornaItensCabecalho(item: PropsDireita | PropsEsquerda, key: number) {
    switch (item) {
      case 'VOLTAR':
        return (
          <BotaoCabecalho
            aria-label="voltar"
            key={key}
            onClick={() => {
              history.back()
            }}
          >
            <div className="icon">
              <BiChevronLeft />
            </div>
          </BotaoCabecalho>
        )
      case 'CONFIGURACOES':
        return (
          <BotaoCabecalho
            aria-label="configurações"
            key={key}
            onClick={() => router.push('/administracao/configuracoes')}
          >
            <div className="icon">
              <FiSettings />
            </div>
          </BotaoCabecalho>
        )
      case 'COMPARTILHAR':
        return (
          <BotaoCabecalho
            aria-label="compartilhar"
            key={key}
            onClick={() => compartilharLink(`${window.location.search}`)}
          >
            <div className="icon">
              <FiShare2 />
            </div>
          </BotaoCabecalho>
        )
      case 'COMPARTILHAR_PESQUISA':
        return (
          <BotaoCabecalho
            aria-label="Compartilhar pesquisa"
            key={key}
            onClick={() => {
              compartilharLink(pesquisaContext.gerarLinkCompartilhar(), 'Veja essa seleção de produtos!')
            }}
          >
            <div className="icon">
              <FiShare2 />
            </div>
          </BotaoCabecalho>
        )
    }
  }

  return (
    <ContainerCabecalho>
      <div className="cabecalho">
        <div className="esquerda">
          <div className="itens">{props.esquerda?.map(retornaItensCabecalho)}</div>
        </div>
        <div className="centro">
          <Link href="/">
            <h1>{props?.titulo || (configsLoja?.nome || 'Carregando').toLowerCase()}</h1>
          </Link>
        </div>
        <div className="direita">
          <div className="itens">{props.direita?.map(retornaItensCabecalho)}</div>
        </div>
      </div>
      <div className="anti-cabecalho" />
    </ContainerCabecalho>
  )
}

const ContainerCabecalho = styled.header`
  .cabecalho {
    display: flex;
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100vw;
    height: 4rem;
    justify-content: space-between;
    align-items: center;
    background: var(--cor-primaria);
    color: var(--branco);
    border-radius: 0 0 0.438rem 0.438rem;

    .itens {
      display: flex;
    }

    .esquerda {
      margin: 0.4rem;
      min-width: 5rem;
      display: grid;
      place-items: center start;
    }

    .centro {
      position: absolute;
      left: 50%;
      top: 28%;
      transform: translate(-50%);

      h1 {
        white-space: nowrap;
        font-family: 'Josefin Sans' !important;
        font-weight: 100;
      }

      @media (max-width: 325px) {
        top: 0.8rem;

        h1 {
          font-size: 1.4rem;
        }
      }
    }

    .direita {
      margin: 0.4rem;
      min-width: 5rem;
      display: grid;
      place-items: center end;
    }
  }

  .anti-cabecalho {
    height: 4rem;
  }
`

const BotaoCabecalho = styled(Botao)`
  border: none;
  box-shadow: none !important;
  font-size: 0;
  background-color: transparent !important;
  color: var(--branco);
  padding: 0.5rem 1rem;
  margin: 0;

  .icon {
    min-height: 1.9rem;
    min-width: 1.9rem;
  }

  @media (max-width: 325px) {
    padding: 0.5rem 0.6rem !important;

    svg {
      font-size: 2rem;
      width: 1.4rem !important;
      height: 1.4rem !important;
    }
  }

  @media (min-width: 326px) {
    svg {
      font-size: 1.8rem;
      width: 1.5rem !important;
      height: 1.5rem !important;
    }
  }
`
