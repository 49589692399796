import styled from 'styled-components'

import tools from '../../../../utils/tools'

interface PrecosProps {
  precoOriginal: number
  preco: number
  valorParcela: number
  parcelas: number
}

const Precos: React.FC<PrecosProps> = props => {
  const PromocaoParcela = !!props.precoOriginal && !!props.valorParcela
  const PromocaoSemParcela = !!props.precoOriginal && !props.valorParcela
  const ParcelaSemPromocao = !props.precoOriginal && !!props.valorParcela

  switch (true) {
    case PromocaoParcela:
      return (
        <ContainerPrecos>
          <span>
            <PrecoOriginal className="preco">
              <del>{tools.formataValorEmReais(props.precoOriginal)}</del>
            </PrecoOriginal>
            <PrecoDesconto className="preco">{tools.formataValorEmReais(props.preco)}</PrecoDesconto>
            no Pix
          </span>
          <PrecoParcela>
            ou {props.parcelas}x de <b className="preco">{tools.formataValorEmReais(props.valorParcela)}</b>
          </PrecoParcela>
        </ContainerPrecos>
      )
    case PromocaoSemParcela:
      return (
        <>
          <PrecoOriginal className="preco">
            <del>{tools.formataValorEmReais(props.precoOriginal || 0)}</del>
          </PrecoOriginal>
          <PrecoDesconto className="preco">{tools.formataValorEmReais(props.preco)}</PrecoDesconto>
        </>
      )
    case ParcelaSemPromocao:
      return (
        <ContainerPrecos>
          <span>
            <b className="preco">{tools.formataValorEmReais(props.preco)}</b>no Pix
          </span>
          <PrecoParcela>
            ou {props.parcelas}x de <b className="preco">{tools.formataValorEmReais(props.valorParcela)}</b>
          </PrecoParcela>
        </ContainerPrecos>
      )
    default:
      return <b className="preco">{tools.formataValorEmReais(props.preco)}</b>
  }
}

const ContainerPrecos = styled.div`
  display: flex;
  flex-direction: column;
  .preco {
    margin-right: 0.25rem;
  }
`

const PrecoParcela = styled.span`
  font-size: 0.8rem;
`

const PrecoOriginal = styled.span`
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--vermelho);
`

const PrecoDesconto = styled.span`
  font-weight: 700;
  color: var(--verde);
`

export default Precos
