import React, { HTMLAttributes, ReactNode } from 'react'
import styled, { css } from 'styled-components'

interface PageLayoutProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  fixed?: boolean
}

export const PageLayout: React.FC<PageLayoutProps> = props => (
  <PageLayoutStyled {...props} fixed={!!props.fixed?.toString()}>
    {props.children}
  </PageLayoutStyled>
)

const PageLayoutStyled = styled.div<{ fixed?: boolean }>`
  ${props =>
    props.fixed
      ? css`
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          z-index: 1000;
        `
      : css`
          height: 100%;
          margin: 0 auto;
        `};
  // Celulares
  @media (max-width: 768px) {
    max-width: auto;
  }
  // Tablets
  @media (min-width: 768px) {
    max-width: 46.875rem;
  }
  // Desktop HD
  @media (max-width: 992px) {
    max-width: 60.625rem;
  }
  // Desktop FULL HD
  @media (max-width: 1200px) {
    max-width: 73.125rem;
  }
`
